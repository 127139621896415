import React, { Component,useEffect,useState } from "react";
import { Link } from 'react-router-dom';
import "./tyrecutting.css"
import './Plasticbaler.css'
import shrederimg from "./images/shredder.jpg"
import shrederimg2 from "./images/shredder2.jpg"
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import shredderpiece1 from "./images/shredderpiece1.jpg"
import shredderpiece2 from "./images/shredderpiece2.jpg"
import shredderpiece3 from "./images/shredderpiece3.jpg"
import shredderimg3 from './images/shredderimg3.jpeg'
import shredderhd from "./images/shredder_hd.jpg"
import shredderhd1 from "./images/shredder_hd1.jpg"
import shredderhdimg from "./images/Shedder _ Final PNG.png"













function Tyreshredder() {


   const handleDownload = (fileName) => {
        // Create a new anchor element
        const link = document.createElement('a');
        // Set the href to the PDF file
        link.href = `${process.env.PUBLIC_URL}/${fileName}.pdf`;
        // Set the download attribute to the desired file name
        link.download = `${fileName}.pdf`;
        // Append the anchor to the body
        document.body.appendChild(link);
        // Programmatically click the anchor
        link.click();
        // Remove the anchor from the body
        document.body.removeChild(link);
    };
    const [activeSection, setActiveSection] = useState('sht6000');
 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


        return (
            <>


                    <div className="carousel-container-tyrescrap">
                        <Carousel
                            showArrows={true}
                            autoPlay={true}
                            infiniteLoop={true}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={true}
                            interval={1500}
                        >
                            <div>
                                <img style={{objectFit:"cover"}} src={shredderpiece3} alt="First Slide" className="carousel-image"/>
                                <p className="legend">Primary Shredder For Tyres</p>
                            </div>
                            <div>
                                <img style={{objectFit:"cover"}} src={shredderpiece2} alt="Second Slide" className="carousel-image" />
                                <p className="legend">Primary Shredder For Tyres</p>
                            </div>
                            <div>
                                <img style={{objectFit:"cover"}} src={shredderpiece1} alt="Third Slide" className="carousel-image"/>
                                <p className="legend">Primary Shredder For Tyres</p>
                            </div>
                        </Carousel>
                    </div>
                    <div className="heading-tyrescrap1primary"> Primary Shredder For Tyres</div>


               <div className="htop-balerurl"> <div className="heading-tyrescrap">Model Numbers </div></div>

               
     
                <div className="headingtyrescraps">
        <b
          className={`headingtyrescrap ${activeSection === 'sht6000' ? 'active' : ''}`}
          onClick={() => setActiveSection('sht6000')}
        >
          SHT 6000
        </b>
        <b
          className={`headingtyrescrap ${activeSection === 'sht8000' ? 'active' : ''}`}
          onClick={() => setActiveSection('sht8000')}
        >
          SHT 8000
        </b>
        <b
          className={`headingtyrescrap ${activeSection === 'sht12000' ? 'active' : ''}`}
          onClick={() => setActiveSection('sht12000')}
        >
          SHT 12000
        </b>
      </div>


          {activeSection === 'sht6000' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img src={shredderhdimg} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>SHT6000</h1>
                                        <p><b>Type of Machine:</b> Shredder</p>
                                        <p><b>Power Range:</b> 120-160 Hp</p>
                                        <p><b>System:</b> Robust Hydraulic System</p>
                                        <p><b>User friendly:</b> Our SHT6000 is equipped with user-friendly controls.</p>
                                        <Link to="/Sht6000" className="link-button">
                                          Read More
                                        </Link>
                                        <button className="download-tyrescrap" onClick={() => handleDownload('sht6000')}>
                                          Download Specification
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}



                      {activeSection === 'sht8000' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img src={shredderhd} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>SHT8000</h1>
                                        <p><b>Type of Machine:</b> Shredder</p>
                                        <p><b>Power Range:</b> 160 -220 HP </p>
                                        <p><b>User friendly:</b> Our SHT8000 is equipped with user-friendly controls.</p>
                                        <Link to="/Sht8000" className="link-button">
                                          Read More
                                        </Link>
                                        <button className="download-tyrescrap" onClick={() => handleDownload('sht8000')}>
                                          Download Specification
                                        </button>  
                                      </div>
                                    </div>
                                  </div>
                                )}



                  {activeSection === 'sht12000' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img style={{"marginTop":"12%","height":"280px"}} src={shredderhd1} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>SHT12000</h1>
                                        <p><b>Type of Machine:</b> Shredder</p>
                                        <p><b>Power Range:</b> 220 -280 HP </p>
                                        <p><b>System:</b> Robust Hydraulic System</p>
                                        <p><b>User friendly:</b> Our SHT12000 is equipped with user-friendly controls.</p>
                                        <Link to="/Sht12000" className="link-button">
                                          Read More
                                        </Link>
                                        <button className="download-tyrescrap" onClick={() => handleDownload('sht12000')}>
                                          Download Specification
                                        </button>
                                     
                                        
                                       
                                      </div>
                                    </div>
                                  </div>
                                )}

            </>
        )

}
export default Tyreshredder