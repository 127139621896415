import { Component, React,useEffect,useState,useRef } from "react";
import { Link } from "react-router-dom";
import logo from "./images/logo.png";
import './home.css'
import { CCarousel, CCarouselItem, CImage, CCarouselCaption } from '@coreui/react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Baler from "./images/baler.png"
import ecosustainimg from "./images/ecosustain.png";
import Baler1 from "./images/baler1.png"
import shredder from "./images/shredder.jpg"
import tyrefolding from "./images/tyre folding1.jpg"
import tyrecutting from "./images/tyre folding2.jpg"
import Tyrecuttingimg from "./images/tyrecutting1.jpg";
import Baler4 from "./images/baler4.png"
import shredder_hd from "./images/shredder_hd.jpg"
import bp5 from "./images/whatsup images/WhatsApp Image 2024-07-11 at 11.56.50 AM.jpeg"
import bp1 from "./images/whatsup images/WhatsApp Image 2024-07-11 at 11.56.51 AM(1).jpeg"
import shredderhdimg from "./images/Shedder _ Final PNG.png"
import ifatimg from "./images/ifat-india-300x218.png"
import envirotechimg from "./images/envirotech/envirotechimg.png"
import { useInView } from "react-intersection-observer";
import homevideo from './images/Tradefair/hometop.mp4'
import shredderhd1 from "./images/shredder_hd1.jpg"
import yrp from "./images/Your Recycling Partner.png"
import bands from "./images/bands.jpeg"
import homenew from "./images/Ads 2.jpeg"
import homenew2 from "./images/Ads 4.jpeg"
import homenew3 from "./images/Ads 5.jpeg"



function Home(){


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [activeSection, setActiveSection] = useState('upcoming');
  

    const { ref, inView } = useInView({
      triggerOnce: true, // Trigger the animation only once when the section enters the viewport
      threshold: 0.1, // Start the animation when 10% of the element is visible
    });


  
  
    const [selectedIndex, setSelectedIndex] = useState(3);

    const images = [
      bp5,shredderhd1,Baler1,Tyrecuttingimg,tyrefolding,

    ];
  
    const moveToSelected = (direction) => {
      let newIndex = selectedIndex;
  
      if (direction === 'next') {
        newIndex = (selectedIndex + 1) % images.length;
      } else if (direction === 'prev') {
        newIndex = (selectedIndex - 1 + images.length) % images.length;
      } else {
        newIndex = direction;
      }
  
      setSelectedIndex(newIndex);
    };
  
    const getClassName = (index) => {
      if (index === selectedIndex) return "selected";
      if (index === (selectedIndex - 1 + images.length) % images.length) return "prev";
      if (index === (selectedIndex - 2 + images.length) % images.length) return "prevLeftSecond";
      if (index === (selectedIndex + 1) % images.length) return "next";
      if (index === (selectedIndex + 2) % images.length) return "nextRightSecond";
      if (index > selectedIndex) return "hideRight";
      return "hideLeft";
    };
  
  
  
 
        return (
            <>




<CCarousel controls indicators className="custom-carousel">
  <CCarouselItem>
    <CImage className="d-block w-100 custom-image img-fluid" src={homenew} alt="slide 1" />
    <CCarouselCaption className="d-block d-md-block">
    <h1 className="balerh1">Your Recycling Partner</h1>
      <h5 className="balerh5">Primary Shredders</h5>
      <p className="balerp">Our shredders efficiently process recyclables, promoting waste reduction. </p>
     
    </CCarouselCaption>
    <div className="productscontainer">

                                  <Link to="/tyreshredder">
                                    <div className="proimagetext">
                                      <img src={shredderhdimg} className="imagepro" />
                                      <h3 className="protext">Tyre shredder</h3>
                                    </div>
                                  </Link>

                                  <Link to="/Rasper">
                                    <div className="proimagetext">
                                      <img src={shredderhdimg} className="imagepro" />
                                      <h3 className="protext">Rasper</h3>
                                    </div>
                                  </Link>

                                  <Link to="/metalshredder">
                                    <div className="proimagetext">
                                      <img src={shredder_hd} className="imagepro" />
                                      <h3 className="protext">Metal Shredder</h3>
                                    </div>
                                  </Link>

                                  <Link to="/plasticshredder">
                                    <div className="proimagetext">
                                      <img src={shredderhd1} className="imagepro" />
                                      <h3 className="protext">Plastic Shredder</h3>
                                    </div>
                                  </Link>

                                  <Link to="/paperandcardboardshredder">
                                    <div className="proimagetext">
                                      <img src={shredderhdimg} className="imagepro" />
                                      <h3 className="protext">Paper shredder</h3>
                                    </div>
                                  </Link>

                                </div>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100 custom-image" src={homenew2} alt="slide 2" />
    <CCarouselCaption className="d-block d-md-block">
      <h1 className="balerh1">Your Recycling Partner</h1>
      <h5 className="balerh5">Balers</h5>
      <p className="balerp">Meets the needs of waste management and recycling industries </p>
   
    </CCarouselCaption>
    <div className="productscontainer">
                                  <Link to="/Baler">
                                    <div className="proimagetext">
                                      <img src={bp5} className="imagepro" />
                                      <h3 className="protext">Tyre scrap Balers</h3>
                                    </div>
                                  </Link>

                                  <Link to="/Plasticbalers">
                                    <div className="proimagetext">
                                      <img src={Baler1} className="imagepro" />
                                      <h3 className="protext">Plastic Balers</h3>
                                    </div>
                                  </Link>
                                    <Link to="/cbpsb">
                                  <div className="proimagetext">
                                    <img src={Baler4} className="imagepro" />
                                    <h3 className="protext">Card Board Balers</h3>
                                  </div></Link>

                                  <Link to="/metalbalers">
                                    <div className="proimagetext">
                                      <img src={Baler} className="imagepro" />
                                      <h3 className="protext">Metal Balers</h3>
                                    </div>
                                  </Link>

                                  
                                </div>
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100 custom-image" src={homenew3} alt="slide 3" />
    <CCarouselCaption className="d-block d-md-block">
    <h1 className="balerh1">Your Recycling Partner</h1>
      <h5 className="balerh5">Tyre Folding</h5>
      <p className="balerp">Equipped with powerful cutting blades and a durable shredding chamber  </p>
     
      
    </CCarouselCaption>
    <div style={{justifyContent: "center"}} className="productscontainer">
                        

                                  <Link to="/Tyre Folding">
                                    <div className="proimagetext">
                                      <img src={tyrefolding} className="imagepro" />
                                      <h3 className="protext">Tyre Folding</h3>
                                    </div>
                                  </Link>
                                </div>
  </CCarouselItem>
</CCarousel>





                
                <div className="homecontainer">

                    <div className="hometopcontainer">
                        <b className="your">Your Partner in Sustainable Recycling</b>
                        <br />
                        <b className="solu">Solutions</b>
                        <div className="at-vikah-ecotech">
                            At Vikah Ecotech, we are dedicated to pioneering innovative solutions in the field of recycling machinery manufacturing. With a commitment to sustainability and environmental stewardship, we design and produce cutting-edge machinery that empowers industries to efficiently process waste materials and contribute to a circular economy.
                        </div>
                    </div>
                </div>

                <main style={{"marginBottom":"0%"}}>
        <div id="carousel">
          {images.map((img, index) => (
            <div key={index} className={getClassName(index)} onClick={() => moveToSelected(index)}>
              <img className="imgcar" src={img} alt={`Carousel ${index + 1}`} />
            </div>
          ))}
        </div>

        <div className="buttonss">
          <button id="prevs" onClick={() => moveToSelected('prev')}><b>Prev</b></button>
          <button id="nexts" onClick={() => moveToSelected('next')}><b>Next </b></button>
        </div>
      </main>
               

                <b className="homeproducts">Our Products</b>
                <div className="card-container-home" ref={ref}>
      <Link to="/bproducts">
        <div className={`card-item-home ${inView ? 'animate' : ''}`}>
          <img className="card-image-home" alt="Baler" src={Baler} />
          <div className="card-text-home">Balers</div>
        </div>
      </Link>
      <Link to="/shredderproducts">
        <div className={`card-item-home ${inView ? 'animate' : ''}`}>
          <img
            style={{ objectFit: "contain" }}
            className="card-image-home"
            alt="Shredders"
            src={shredderhdimg}
          />
          <div className="card-text-home">Shredders</div>
        </div>
      </Link>
      <Link to="/Tyre cutting">
        <div className={`card-item-home ${inView ? 'animate' : ''}`}>
          <img
            className="card-image-home"
            alt="Cutting Equipment"
            src={Tyrecuttingimg}
          />
          <div className="card-text-home">
            <p>Cutting</p>
            <p>Equipment</p>
          </div>
        </div>
      </Link>
      <Link to="/Tyre Folding">
        <div className={`card-item-home ${inView ? 'animate' : ''}`}>
          <img
            className="card-image-home"
            alt="Folding Equipment"
            src={tyrefolding}
          />
          <div className="card-text-home">
            <p>Folding</p>
            <p>Equipment</p>
          </div>
        </div>
      </Link>
    </div>


                <div>
      {/* Headings with Click Handlers */}
      <div className="tradeHeadings">
        <b 
          className={`tradeheading ${activeSection === 'upcoming' ? 'active' : ''}`} 
          onClick={() => setActiveSection('upcoming')}
        >
          Upcoming Trade Fairs
        </b>
        <b 
          className={`tradeheading ${activeSection === 'completed' ? 'active' : ''}`} 
          onClick={() => setActiveSection('completed')}
        >
          Completed Trade Fairs
        </b>
      </div>

      {activeSection === 'upcoming' && (
       <div className="setter">
        <div className="containertrade">
           
          <div className="trade1">
            <img alt="Trade Fair" className="envirotechimg" src={envirotechimg} />
            <p className="ifthead">EnviroTech Asia 2024 is a premier International Exhibition and Summit focused on sustainable environmental practices.</p>
            <div className="details">

             The 7th edition will provide a platform for business leaders, investors, government officials, industry experts, 
             and NGOs to share insights and opportunities in environmental protection and green business.
              It will be one of the largest gatherings in the environment sector,
               bringing together key stakeholders to drive sustainable development and environmental preservation.
            </div>
            <div className="date">
              <span><b>Date of Exhibition:</b> 3,4,5 Dec 2024</span>
            </div>
            <div className="location">
              <span><b>Location:</b> Yashobhoomi Convention Center (IICC),Dwarka, Delhi, 110061</span>
            </div>
            {/* <div className="booth">
              <span style={{ fontSize: "105%" }}><b>Booth No: C019 </b></span>
            </div> */}
            <a href="https://envirotechasia.com/env/">
              <div className="tradelink">
                <span><b className="knowifat">Know more about Envirotech Asia</b></span>
              </div>
            </a>
           
          </div>
       </div>
          </div>
           )}

      {/* Conditionally Render Sections Based on State */}
      {activeSection === 'completed' && (
        <div className="containertrade">
          <div className="trade1">
            <img alt="Trade Fair" className="ifatimg" src={ifatimg} />
            <p className="ifthead">India's Leading Trade Fair for Water, Sewage, Solid Waste & Recycling</p>
            <div className="details">
              IFAT India has been bringing together professionals and experts from various sectors of the water, sewage, waste, and recycling industry, 
              providing the entire industry with a platform for knowledge exchange, networking, and the opportunity to forge partnerships between industry players, policy makers, and environmental experts.
            </div>
            <div className="date">
              <span><b>Date of Exhibition:</b> 16, 17, 18 Oct 2024</span>
            </div>
            <div className="location">
              <span><b>Location:</b> Mumbai Exhibition Centre</span>
            </div>
            <div className="booth">
              <span style={{ fontSize: "105%" }}><b>Booth No: D016, Hall:3 </b></span>
            </div>
            <a href="https://ifat-india.com/en/">
              <div className="tradelink">
                <span><b className="knowifat">Know more about IFAT India</b></span>
              </div>
            </a>
            <Link to="/Ifat" className="buttonctf">
              <span style={{ color: "black" }}>Images and Videos</span>
            </Link>
          </div>
          <div className="trade1">
            <img alt="Trade Fair" src={ecosustainimg} />
            <div className="details">
              Eco Sustain Expo &amp; Conference, an exceptional event meticulously crafted by HITEX, Hyderabad that brings together a diverse community of professionals, visionaries, policymakers, researchers, and solution providers, all bound by a resolute dedication to shaping a sustainable future.
            </div>
            <div className="date">
              <span><b>Date of Exhibition:</b> 16, 17, 18 August 2024</span>
            </div>
            <div className="location">
              <span><b>Location:</b> HITEX Exhibition Center, Hyderabad</span>
            </div>
            <div className="booth">
              <span style={{ fontSize: "108%" }}><b>Booth No: 4N01 </b></span>
            </div>
            <a href="https://ecosustainexpo.in/">
              <div className="tradelink">
                <span><b className="know">Know more about Ecosustain Expo 2024</b></span>
              </div>
            </a>
            <Link to="/CompletedTradefair1" className="buttonctf">
              <span style={{ color: "black" }}>Images and Videos</span>
            </Link>
          </div>
        </div>
      )}

    </div>



                <div className="testimonials">
                    <h2 className="testimonialhead">Testimonials</h2>
                    <Carousel showArrows={true} infiniteLoop={true} showThumbs={false} showStatus={false} autoPlay={true} interval={5000}>
                        <div className="testimonial">
                            <img src={Baler} alt="Testimonial 1" className="testimonial-img" style={{ height: "250px" }} />
                            <div className="testimonial-content">
                                <h3>EUROPEAN ASIAN METALS LTD (UK)</h3>
                                <p>"We recently purchased a Baler machine from Vikah Ecotech and we couldn’t be happier with the product. The quality of the machine is top notch, and it has significantly increased our baling production. The customer service provided by the team at Vikah Ecotech was exceptional. We highly recommend Vikah Ecotech machinery to anyone in the recycling industry."</p>
                                <span className="customer-name">- J.Shannu</span>
                            </div>
                        </div>
                        <div className="testimonial">
                            <img src={tyrecutting} alt="Testimonial 2" className="testimonial-img" style={{ height: "250px" }} />
                            <div className="testimonial-content">
                                <h3>LG ENVIRONMENTAL SERVICES LLC (SAUDI ARABIA)</h3>
                                <p>"I have been using Vikah Ecotech Tyre Cutting and Folding equipments and I have to say that their machines are built to last. I recently added a shredder line and it has been performing beyond my expectations. The precision and reliability of their shredders are truly impressive. Thank you Vikah Ecotech for providing such high quality machines."</p>
                                <span className="customer-name">- Mohammad El Assai</span>
                            </div>
                        </div>
                        <div className="testimonial">
                            <img src={Baler1} alt="Testimonial 3" className="testimonial-img" style={{ height: "250px" }} />
                            <div className="testimonial-content">
                                <h3>MTC (NEW ZEALAND)</h3>
                                <p>After extensive research, we decided to purchase a new baler machine from Vikah Ecotech, and it was one of the best decisions we made for our business. The machine has streamlined our baling process, saving us both time and money.  The time at Vikah Ecotech was extremely helpful throughout the purchasing process guiding all customizable solutions and also their after sales support has been fantastic.  We are extremely satisfied with our purchase.</p>
                                <span className="customer-name">- Capt Ject</span>
                            </div>
                        </div>
                        <div className="testimonial">
                            <img src={Baler4} alt="Testimonial 4" className="testimonial-img" style={{ height: "250px" }} />
                            <div className="testimonial-content">
                                <h3>VENKATA SIVA SAI INDUSTRIES (INDIA)</h3>
                                <p>We recently bought a baler machine for waste tyres and the quality of the machine is exceptionally good with no maintenance. The quality of the structure and the baling force are top quality.  After sales service by the team in Vikah are very helpful and attending all our queries in no time.  Happy with their service.  Keep going Vikah Ecotech.</p>
                                <span className="customer-name">- K.Kumar</span>
                            </div>
                        </div>
                    </Carousel>
                </div>










            </>
        );
    }


export default Home