import React from "react";
import { Component } from "react";
import ecosustainimg from "./images/ecosustain.png";
import './Tradefair.css'
import ifatimg from "./images/ifat-india-300x218.png"
import { Link } from "react-router-dom";





class Trade extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <>
            
            <b className="tradefairtradeheading">Trade Fairs</b>
                   

                  
            <div className="containertrade">
          <div className="trade1">
            <img alt="Trade Fair" className="ifatimg" src={ifatimg} />
            <p className="ifthead">India's Leading Trade Fair for Water, Sewage, Solid Waste & Recycling</p>
            <div className="details">
              IFAT India has been bringing together professionals and experts from various sectors of the water, sewage, waste, and recycling industry, 
              providing the entire industry with a platform for knowledge exchange, networking, and the opportunity to forge partnerships between industry players, policy makers, and environmental experts.
            </div>
            <div className="date">
              <span><b>Date of Exhibition:</b> 16, 17, 18 Oct 2024</span>
            </div>
            <div className="location">
              <span><b>Location:</b> Mumbai Exhibition Centre</span>
            </div>
            <div className="booth">
              <span style={{ fontSize: "105%" }}><b>Booth No: D016, Hall:3 </b></span>
            </div>
            <a href="https://ifat-india.com/en/">
              <div className="tradelink">
                <span><b className="knowifat">Know more about IFAT India</b></span>
              </div>
            </a>
            <Link to="/Ifat" className="buttonctf">
              <span style={{ color: "black" }}>Images and Videos</span>
            </Link>
          </div>
          <div className="trade1">
            <img alt="Trade Fair" src={ecosustainimg} />
            <div className="details">
              Eco Sustain Expo &amp; Conference, an exceptional event meticulously crafted by HITEX, Hyderabad that brings together a diverse community of professionals, visionaries, policymakers, researchers, and solution providers, all bound by a resolute dedication to shaping a sustainable future.
            </div>
            <div className="date">
              <span><b>Date of Exhibition:</b> 16, 17, 18 August 2024</span>
            </div>
            <div className="location">
              <span><b>Location:</b> HITEX Exhibition Center, Hyderabad</span>
            </div>
            <div className="booth">
              <span style={{ fontSize: "108%" }}><b>Booth No: 4N01 </b></span>
            </div>
            <a href="https://ecosustainexpo.in/">
              <div className="tradelink">
                <span><b className="know">Know more about Ecosustain Expo 2024</b></span>
              </div>
            </a>
            <Link to="/CompletedTradefair1" className="buttonctf">
              <span style={{ color: "black" }}>Images and Videos</span>
            </Link>
          </div>
          
        </div>
                    

                


                    


            
                
            
            </>
        )
    }
}

export default Trade



