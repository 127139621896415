import React, { Component,useState,useEffect } from "react";
import { Link } from 'react-router-dom';

import "./tyrecutting.css"

import tyrescrap2 from './images/tyre scrap2.jpg'
import tyrescrap3 from "./images/tyre scrap3.jpg"
import tyrescrap4 from "./images/tyre scrap4.jpg"

import Balerimg from "./images/baler.png"
import Baler1 from "./images/baler1.png"
import Baler2 from "./images/baler2.png"
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import tyreVideo from './images/whatsup images/balereditvideo.mp4';





function Baler()  {

    
   const  handleDownload = (fileName) => {
        // Create a new anchor element
        const link = document.createElement('a');
        // Set the href to the PDF file
        link.href = `${process.env.PUBLIC_URL}/${fileName}.pdf`;
        // Set the download attribute to the desired file name
        link.download = `${fileName}.pdf`;
        // Append the anchor to the body
        document.body.appendChild(link);
        // Programmatically click the anchor
        link.click();
        // Remove the anchor from the body
        document.body.removeChild(link);
    };
    const [activeSection, setActiveSection] = useState('blt150');
 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  
  
        return (
            <>
         
                    
                    <div className="carousel-container-balerurl">
                        <Carousel
                            showArrows={true}
                            autoPlay={true}
                            infiniteLoop={true}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={true}
                            interval={1500}
                        >
                            <div>
                                <img style={{width:"100%",height:"367px",objectFit:"cover"}} src={tyrescrap2} alt="First Slide" className="carousel-image"/>
                                <p className="legend">Tyre scrap image1</p>
                            </div>
                            <div>
                                <img style={{width:"100%",height:"367px",objectFit:"cover"}} src={tyrescrap3} alt="Second Slide" className="carousel-image" />
                                <p className="legend">Tyre scrap image2</p>
                            </div>
                            <div>
                                <img style={{width:"100%",height:"367px",objectFit:"cover"}} src={tyrescrap4} alt="Third Slide" className="carousel-image"/>
                                <p className="legend">Tyre scrap image3</p>
                            </div>
                        
                
                                                                <div>
                                    <video style={{width:"100%",height:"367px",objectFit:"contain"}} className="carousel-image" controls muted>
                                        <source src={tyreVideo} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <p className="legend">Tyre scrap video</p>
                                    </div>

                        </Carousel>
                    </div>
                    <div className="heading-balerurl"> Tyre Scrap Balers</div>


                <div className="htop-balerurl"><div className="heading-tyrescrap">Model Numbers </div></div>

                <div className="headingtyrescraps-balerurl">
        <b
          className={`headingtyrescrap ${activeSection === 'blt150' ? 'active' : ''}`}
          onClick={() => setActiveSection('blt150')}
        >
          BLT 150
        </b>
        <b
          className={`headingtyrescrap ${activeSection === 'blt200' ? 'active' : ''}`}
          onClick={() => setActiveSection('blt200')}
        >
          BLT 200
        </b>
        <b
          className={`headingtyrescrap ${activeSection === 'blt250' ? 'active' : ''}`}
          onClick={() => setActiveSection('blt250')}
        >
          BLT 250
        </b>
      </div>

      {/* {activeSection === 'blt150' && (
        <div className="bot">
          <Link to="/Blt150">
            <div className="card-container1-cutting-tyrescrap">
              <div className="card-item-cutting">
                <img className="balersetimg" alt="Baler" src={Balerimg} />
                <div className="title-cutting">BLT150</div>
                <div className="rating-cutting">★★★★★</div>
                <div className="description1-cutting">
                  <b>Type of Machine:</b> Baler<br />
                  <b>Size:</b> 1530 mm * 2700 mm * 1200 mm<br />
                  <b>System:</b> Robust Hydraulic System<br />
                  <b>User friendly:</b> Our BLT-150 is equipped with user-friendly controls.<br />
                  <b>Result:</b> Operators can easily and safely operate the BLT-150.
                </div>
                <button className="download-tyrescrap" onClick={() => handleDownload('blt150')}>
                  Download Specification
                </button>
              </div>
            </div>
          </Link>
        </div>
      )} */}

                          
                                {activeSection === 'blt150' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img src={Balerimg} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>BLT150</h1>
                                        <p><b>Type of Machine:</b> Baler</p>
                                        <p><b>Compaction Force: </b>150 Ton</p>
                                        <p><b>Baler Weight :</b>9000 kg </p>
                                        <p><b>Result:</b> Operators can easily and safely operate the BLT-150.</p>
                                        <Link to="/Blt150" className="link-button">
                                          Read More
                                        </Link>
                                        <button className="download-tyrescrap" onClick={() => handleDownload('blt150')}>
                                          Download Specification
                                        </button>
                                     
                                        
                                       
                                      </div>
                                    </div>
                                  </div>
                                )}



                              {activeSection === 'blt200' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img src={Baler1} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>BLT200</h1>
                                        <p><b>Type of Machine:</b> Baler</p>
                                        <p><b>Compaction Force::</b> 200 Ton </p>
                                        <p><b>Baler Weight :</b>9000 kg </p>
                                        <p><b>Function:</b> Our BLT-200 is engineered to efficiently compress.</p>
                                        <Link to="/Blt200" className="link-button">
                                          Read More
                                        </Link>
                                        <button className="download-tyrescrap" onClick={() => handleDownload('blt200')}>
                                          Download Specification
                                        </button>
                                     
                                        
                                       
                                      </div>
                                    </div>
                                  </div>
                                )}
                                
                                {activeSection === 'blt250' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img src={Baler2} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>BLT250</h1>
                                        <p><b>Type of Machine:</b> Baler</p>
                                        <p><b>Compaction Force::</b> 250 Ton  </p>
                                        <p><b>Baler Weight :</b>11000 kg  </p>
                                        <p><b>Result:</b> Operators can easily and safely operate the BLT-250.</p>
                                        <Link to="/Blt250" className="link-button">
                                          Read More
                                        </Link>
                                        <button className="download-tyrescrap" onClick={() => handleDownload('blt250')}>
                                          Download Specification
                                        </button>
                                     
                                        
                                       
                                      </div>
                                    </div>
                                  </div>
                                )}


     
       
        </>
        
             
        )
    }

export default Baler