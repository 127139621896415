import React from 'react';
import { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';

import './ProductsPage.css';
import shredderhd from "./images/Rasper.jpeg"
import shredderhd1 from "./images/Rasper.jpeg"
import shredderhdimg from "./images/Rasper.jpeg"




const Sht4000 = () => {
    const [mainImage, setMainImage] = useState(shredderhd1);

    const handleImageClick = (image) => {
        setMainImage(image);
    };

  

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const machinery = 'shredder'; 
    const model = 'Rasper';

    const handleDownload = (fileName) => {
        // Create a new anchor element
        const link = document.createElement('a');
        // Set the href to the PDF file
        link.href = `${process.env.PUBLIC_URL}/${fileName}.pdf`;
        // Set the download attribute to the desired file name
        link.download = `${fileName}.pdf`;
        // Append the anchor to the body
        document.body.appendChild(link);
        // Programmatically click the anchor
        link.click();
        // Remove the anchor from the body
        document.body.removeChild(link);
    };







    return (
        <>
        <div className='productpagetop'>
        <div className='imagetop'>
                <div className="main-image-container">
                    <img src={mainImage} alt="sht2000" className="sht2000img" />
                </div>
                <div className="additional-images">
                    <div>
                        <img 
                            className='angle1' 
                            src={shredderhdimg} 
                            alt="Angle 1" 
                            onClick={() => handleImageClick(shredderhdimg)} 
                        
                        />
                    </div>
                    <div>
                        <img 
                            className='angle2' 
                            src={shredderhd} 
                            alt="Angle 2" 
                            onClick={() => handleImageClick(shredderhd)} 
                          
                        />
                    </div>
                    <div>
                        <img 
                            className='angle3' 
                            src={shredderhd1} 
                            alt="Angle 3" 
                            onClick={() => handleImageClick(shredderhd1)} 
                          
                        />
                    </div>
                </div>
            </div>
            <div className="productdetails">
                <h1>{model}</h1>
                <div className="reviews">
                    <span className="stars">★★★★★</span>
                    <span>5.0 from 392 Reviews</span>
                </div>
                <p>
                The Rasper Model R-6000 is an advanced tyre processing machine designed for efficient recycling of shredded tyres.
                 Engineered to handle various tyre sizes and types, including PCR and TBR, this high-performance model features 
                 precision-cutting blades and a durable granulating chamber. It transforms shredded materials into uniform granules
                  suitable for diverse recycling applications while operating quietly and efficiently. With its compact design and 
                  user-friendly interface, the R-6000 seamlessly integrates into existing recycling lines, 
                delivering reliable and consistent results that prepare shredded tyres for further processing or repurposing.
                </p>
              
            </div>
        </div>


        <div className='features-container'>
            <h2>FEATURES AND SPECIFICATIONS</h2>
            <div className='features-content'>
                <img src={shredderhd} alt="Specifications" className="specifications-image" /> 
                <div className='sht200specification'>
           
                    <p>
        ➤ Durable, hard-faced knives.  <br></br>
        ➤Easy maintenance service platform.  <br></br>
        ➤ Full PLC control panel. <br></br>
        ➤ Stop and auto-reversal motors.  <br></br>
        ➤ Specific Stop/Auto Reversal motors Feature  <br></br>
        ➤ Shaft double protected with drive and mechanical mechanism<br></br>  to avoid damage from in-spherical objects. <br></br>
                    </p>
                </div>
            </div>
        </div>





    <h1 className="technical-heading">Technical Specifications</h1>

    <div className="featurestable">
        <h3>Electrical System</h3>
        <table>
            <tbody>
                <tr>
                    <td>Power</td>
                    <td>160 - 220 HP</td>
                </tr>
                <tr>
                    <td>No Of Motors</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Voltage</td>
                    <td>As per Clients Requirement</td>
                </tr>
                <tr>
                    <td>Drive</td>
                    <td>Electric</td>
                </tr>
                <tr>
                    <td>Panel</td>
                    <td>MCC (Shall be suitable to 415v, 3 Phase, 50Hz AC Supply Drive)</td>
                </tr>
                <tr>
                    <td>Motor Make</td>
                    <td>Siemens / Abb / Crompton</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div className="cuttingsection">
        <h3>Cutting Section</h3>
        <table>
            <tbody>
                <tr>
                    <td>Cutting Chamber:</td>
                    <td>1600 x 960 mm</td>
                </tr>
                <tr>
                    <td>Blades Thickness</td>
                    <td>45 mm</td>
                </tr>
                
                <tr>
                    <td>Hopper Height</td>
                    <td>1400 mm</td>
                </tr>
                <tr>
                    <td>Discharge Height</td>
                    <td>2550 mm</td>
                </tr>
                <tr>
                    <td>Feeding Mechanism</td>
                    <td>Auto</td>
                </tr>
                
            </tbody>
        </table>
    </div>

    <div className="dimensions">
        <h3>Dimensions and Weight</h3>
        <table>
            <tbody>
                <tr>
                    <td>Equipment Length</td>
                    <td>6000 mm</td>
                </tr>
                <tr>
                    <td>Equipment Width</td>
                    <td>2200 mm</td>
                </tr>
                <tr>
                    <td>Equipment Height</td>
                    <td>4855 mm</td>
                </tr>
                <tr>
                    <td>Transport Height (H)</td>
                    <td>2700 mm</td>
                </tr>
                <tr>
                    <td>Equipment Weight (Approx)</td>
                    <td>22500 Kilogram</td>
                </tr>
            </tbody>
        </table>
    </div>




    <div class="buttons-container">
    <Link to={`/login?machinery=${machinery}&model=${model}`}>
        <button className="login-button">Enquire Us</button>
    </Link>

    <button className="downloadspeci-button" onClick={() => handleDownload('sht8000')}>
        Download Specifications
    </button>
</div>
















</>

    );
};

export default Sht4000;

